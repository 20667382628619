import * as React from "react"
import Layout from "../components/Layout"

const NotFoundPage = () => {
  return (
    <Layout>
      <h1>Page Not Found...</h1>
    </Layout>
  )
}

export default NotFoundPage
